import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from '../components/section';
import Frame from '../components/frame';
import Button from '../components/button';
import DotBackground from '../components/dotbackground';

import content from '../data/static-content.js';
import { dimensions } from '../shared/styles';


const MainSection = styled(Section).attrs(props => ({
  wrapperStyle : {
    height : '100%'
  }
}))`
  height : 100%;
  width : 100%;
  min-height : 80rem;
  max-height : 110rem;
  background-color : white;
  padding-top : ${dimensions.headerHeight};
  position : relative;
  display : flex;
  flex-direction : column;
  align-items : center;
  justify-content : center;
`;

const Paragraph = styled.p`
  line-height : 3rem;
  font-size : 1.6rem;
`;

const BackButton = styled(Button)`
  position: absolute;
  right: -10rem;
  bottom: -5rem;

  & a {
    color : white;
    text-decoration : none;
  }
`;

const MessageFrame = styled(Frame).attrs(props => ({
  contentStyle : {
    paddingBottom : '4rem'
  }
}))``;

const Background = styled(DotBackground)`
  position : absolute;
  top : 15%;
  left : 10%;
  width : 50%;
  height : 70%;
`;

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Appunto" />
      <MainSection>
        <Background />
        <MessageFrame tr="pink" bl tl>
          <h1>{content.messageSent.title}</h1>
          {
            content.messageSent.description.map(
              paragraph =>
              <Paragraph>{paragraph}</Paragraph>
            )
          }
          <Link to="/"><BackButton>{content.messageSent.backButton}</BackButton></Link>
        </MessageFrame>
      </MainSection>
    </Layout>
  );
}

export default ContactPage
